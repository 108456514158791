import { render, staticRenderFns } from "./AdminShopArticleCategoryTable.vue?vue&type=template&id=4fbcbf30&"
import script from "./AdminShopArticleCategoryTable.vue?vue&type=script&lang=ts&"
export * from "./AdminShopArticleCategoryTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VFileInput,VTextField})
