




























































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticleCategory } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeNameTable from "@/components/admin/language/name/AdminLangCodeNameTable.vue";

/**
 * AdminShopArticleCategoryTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeNameTable,
    },
})
export default class AdminShopArticleCategoryTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private articleCategories: ArticleCategory[] = [];

    private defaultArticleCategoryItem: ArticleCategory = {
        id: -1,
        names: [],
        image: null,
        sales: 0,
        orderCount: 0,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "names",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.articleCategories = await this.fetchArticleCategories();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all article categories
     *
     * @returns Promise<ArticleCategory[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleCategories(): Promise<ArticleCategory[]> {
        const response = (
            await axios.get<APIResponse<ArticleCategory[]>>(
                "/admin/articles/categories"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
