var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article.category', 100),"items":_vm.articleCategories,"headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultArticleCategoryItem,"crudPermissionSubject":"ArticleCategory","crudURLPrefix":"/admin/article/category"},on:{"crudDialogSave":function (updatedItems) {
            _vm.articleCategories = updatedItems;
            _vm.$root.$emit('articleCategoriesUpdated', updatedItems);
        }},scopedSlots:_vm._u([{key:"item.names",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.names.map(function (langCodeName) { return langCodeName.name; }).join(", "))+" ")]}},{key:"crud-form",fn:function(ref){
        var item = ref.item;
return [_c('AdminLangCodeNameTable',{attrs:{"editable":"","langCodeNames":item.names},on:{"crudDialogSave":function (updatedLangCodeNames) { return (item.names = updatedLangCodeNames); }}}),_c('v-file-input',{attrs:{"value":item.image != undefined ? item.image.fileObject : null,"label":_vm.$t('image.fileObject'),"accept":"image/png, image/jpeg","clearable":"","show-size":""},on:{"change":function (image) {
                    if (image == null) {
                        item.image = null;
                    } else {
                        item.image = {
                            id: -1,
                            src: '',
                            fileObject: image,
                        };
                    }
                }}}),(item.image !== null)?_c('v-text-field',{attrs:{"label":_vm.$t('image.alt')},model:{value:(item.image.alt),callback:function ($$v) {_vm.$set(item.image, "alt", $$v)},expression:"item.image.alt"}}):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }