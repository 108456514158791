






import { Component, Vue } from "vue-property-decorator";
import AdminShopArticleCategoryTable from "@/components/admin/shop/article/category/AdminShopArticleCategoryTable.vue";

/**
 * AdminShopArticleCategoryManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopArticleCategoryTable,
    },
})
export default class AdminShopArticleCategoryManagement extends Vue {}
